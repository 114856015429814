.header-container {
    width: 100%;
    background: url('../../public/images/header-bg-desktop.png') no-repeat center center;
    background-size: cover;
    height: 95vh;
    box-shadow: inset 0 0 0 8.5px var(--dark-blue), 0px 73px 35px 0px rgba(0, 0, 0, 0.20) inset;
    position: relative;
    transform: translateY(-70px);
    overflow: hidden;
}
.header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    padding-top: 5%;
    position: relative;
    z-index: 50;
}
.header-content p {
    color: var(--white); 
    text-align: center;
    font-family: Outfit;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 32.5px */
    max-width: 650px;
    z-index: 100;
}
.header-content button {
    scale: 1.5;
    z-index: 100;
}
.header-content button:hover {
    cursor: pointer;
    scale: 1.55;
    transition: ease-in-out;
}
.header-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: absolute;
    top: 50%; 
    left: 36px; 
    transform: translateY(-50%);
    z-index: 100;
}
.header-socials a i::before {
    font-size: 25px;
    color: var(--white);
}
.header-socials a i:hover::before {
    text-shadow: 2px 2px 0px var(--orange);
}
.header-james {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:  center;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 50px;  
}
.header-james img {
    max-width: 200px;
}

.header-coin-1 {
    position: absolute;
    width: 98px;
    height: auto;
    top: 175px;
    left: -36px;
    transform: rotate(20.318deg);
}
.header-coin-2 {
    position: absolute;
    width: 68px;
    height: auto;
    top: 300px;
    left: 30%;
}
.header-coin-3 {
    position: absolute;
    width: 52px;
    height: auto;
    top: 250px;
    right: 30%;
}
.header-coin-4 {
    position: absolute;
    width: 98px;
    height: auto;
    top: 83px;
    right: -50px;
}
.header-coin-5 {
    position: absolute;
    width: 61px;
    height: auto;
    left: 32px;
    bottom: 216px;
}
.header-coin-6 {
    position: absolute;
    width: 61px;
    height: auto;
    right: 25%;
    bottom: 110px;
}
.header-coin-7 {
    position: absolute;
    width: 61px;
    height: auto;
    left: 35%;
    bottom: 25px;
}
.header-coin-8 {
    position: absolute;
    width: 61px;
    height: auto;
    right: -28px;
    bottom: 40%;
}
.header-logo {
    max-width: 515px;
}


@media only screen and (max-width: 850px) {
    .header-content {
        scale: 0.8;
    }
    .header-socials {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 25px; 
        position: absolute;
        bottom: 40px; 
        transform: translatex(-50%);
    }
    .header-content button {
        scale: 1.2;
    }
    .header-container {
        transform: translateY(-60px);
    }
    .header-james {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:  center;
        z-index: 1;
        position: absolute;
        transform: translateX(-60%);
        width: 100%;
        padding: 0 50px;
        scale: 0.8;  
    }

}
@media only screen and (max-width: 550px) {
    /* .header-james {
        scale: 0.6;
        width: 100vw;
        padding: 0;
        transform: translate(-65px, -43%);
        gap: 25px;
    } */
    .header-coin-7 {
        left: 5%;
        bottom: 25px;
    }
}
