.eras-container {
    width: 100%;
    background: #1F233C;
    padding-bottom: 25px;
    position: relative;
    overflow: hidden;

}
.eras-separation {
    transform: translateY(-3px);
    width: 100vw;
}
.eras-content {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 8% 40px 8%;
}
.eras-chad-container {
    max-width: 350px;
    z-index: 10;
}

.eras-chad-text h2 {
    color: #1F233C;
    text-align: center;
    font-family: Outfit;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    line-height: 135%; /* 33.75px */
    background: url('../../public/images/eras-bd.png');
    background-size: contain; 
    background-position: center;
    background-repeat: no-repeat; 
}
.eras-listing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
}
.eras-listing-box {
    display: flex;
    align-items: center;
    gap: 35px;
}
.eras-listing-box img {
    width: 30%;
}
.eras-listing-text {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.eras-listing-text h3 {
    color: var(--white, #FFF);
    font-family: var(--outfit);
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 25.2px; /* 105% */
    text-transform: uppercase;
}
.eras-listing-text p {
    color: var(--white, #FFF);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25.2px; /* 126% */
    letter-spacing: 0.154px;
}

.eras-coin-1 {
    position: absolute;
    width: 42px;
    height: auto;
    top: 118px;
    left: 240px;
    transform: rotate(20.318deg);
}
.eras-coin-2 {
    position: absolute;
    width: 21px;
    height: auto;
    top: 50px;
    right: 50%;
}
.eras-coin-3 {
    position: absolute;
    width: 42px;
    height: auto;
    top: 87px;
    right: -20px;
}
.eras-coin-4 {
    position: absolute;
    width: 42px;
    height: auto;
    top: 50%;
    left: -22px;
}
.eras-coin-5 {
    position: absolute;
    width: 21px;
    height: auto;
    left: 30%;
    top: 50%;
}
.eras-coin-6 {
    position: absolute;
    width: 21px;
    height: auto;
    left: 183px;
    bottom: 114px;
}
.eras-coin-7 {
    position: absolute;
    width: 21px;
    height: auto;
    left: 35%;
    bottom: 90px;
}
.eras-coin-8 {
    position: absolute;
    width: 61px;
    height: auto;
    right: 34px;
    bottom: 184px;
}
@media only screen and (max-width: 1050px)  {
    .eras-content {
        padding: 0 4% 40px 4%;
    }   
   }
@media only screen and (max-width: 850px)  {
 .eras-content {
    flex-direction: column;
    gap: 0;
 }
 .eras-chad-container {
    scale: 0.8;
 } 
 .eras-container {
    max-height:  none;
}
.eras-listing-box img {
    width: 60%;
}
}
@media only screen and (max-width: 550px)  {
    .eras-listing-box {
       flex-direction: column;
    }  
    .eras-listing-text {
        align-items: center
    } 
    .eras-listing-text h2,
    .eras-listing-text p {
        text-align: center;
    } 
   }

