@font-face {
    font-family: 'TOMOBossaBlack';
    src: url('../fonts/TOMOBossaBlack.ttf') format('truetype');
  }
.newsletter-section {
    width: 100%;
    background-color: var(--white);
    padding: 50px 0;
} 
.newsletter-container {
    width: 80%;
    margin: auto;
    display: flex;
    padding: 50px;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    align-self: stretch;
    border-radius: 25px;
    background: url('../../public/images/newsletter-bg.png') lightgray 50% / cover no-repeat;
}
.newsletter-title {
    color: var(--white);
    text-align: center;
    font-family: "TOMOBossaBlack";
    font-size: 30px;
    font-weight: 900;
}
.newsletter-text {
    color: var(--white, #FFF);
    text-align: center;
    font-family: var(--outfit);
    font-size: 18px;
    font-weight: 300;
    line-height: 145%; /* 26.1px */
}
.newsletter-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.newsletter-box {
    display: flex;
    align-items: center;
    gap: 13px;
}
.newsletter-box input {
    border-radius: 1px;
    background: var(--white, #FFF);
    box-shadow: 3px 3px 0px 0px #FC7200;
    border: none;
    width: 420px;
    height: 44px;
    padding-left: 14px;
}
.send-btn {
    height: 44px;
    padding-left: 35px;
    padding-right: 35px;
}
.newsletter-box input::placeholder {
    color: #1f233cc2;
    font-family: var(--outfit);
    font-size: 12px;
    font-weight: 500;
    line-height: 100%; /* 12px */
}
.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}
.checkbox-container p,
.checkbox-container p a {
    color: #9C9C9C;
    font-family: var(--outfit);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}
.checkbox-container p span {
    color: var(--yellow);
}
.checkbox-container p a {
    text-decoration: underline;
}

  
  .checkbox-container input {
    cursor: pointer;
  }


  @media only screen and (min-width: 425px) and (max-width: 900px) {
    .newsletter-box input  {
        width: 350px;
    }
    .send-btn {
        padding-left: 15px;
        padding-right: 15px;
    }
  }

  @media only screen and (max-width: 700px) {
    .newsletter-container {
        width: 95%;
    }
  }
  @media only screen and (max-width: 600px) {
    .newsletter-box {
        flex-direction: column;
    }
    .newsletter-container {
        padding: 25px;
    }
    .newsletter-title {
        font-size: 25px;
    }
    .newsletter-text {
        font-size: 16px;
    }
  }
  @media only screen and (max-width: 424px) {
    .newsletter-box input  {
        width: 300px;
    }
  }