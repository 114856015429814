/* CookiePopup.css */
.cookie-popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 350px;
    background-color: #f4f4f4;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000; /* Assurez-vous qu'elle est au-dessus d'autres éléments */
  }
  
  .cookie-popup p {
    margin: 0;
    font-size: 14px;
    font-family: var(--outfit);
    color: var(--dark-blue);
  }
  

  .cookie-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .allow-btn {
    display: flex;
    padding: 0px 20px 4px 20px;
    justify-content: center;
    align-items: center;
    gap: 7.027px;
    border-radius: 0.8px;
    background: #FDCF07;
    box-shadow: 3.2px 3.2px 0px 0px #FC7200;
    color: var(--white, #FFF);
    text-align: center;
    font-family: "TOMOBossaBlack";
    font-size: 21.583px;
    font-weight: 900;
    border: none;
    z-index: 100;
}
.allow-btn:hover,
.refuse-btn:hover {
  cursor: pointer;
  scale: 1.05;
  transition: ease-in-out;
}
.refuse-btn {
  display: flex;
  padding: 0px 20px 4px 20px;
  justify-content: center;
  align-items: center;
  gap: 7.027px;
  border-radius: 0.8px;
  background: #a1a1a1;
  box-shadow: 3.2px 3.2px 0px 0px #505050;
  color: var(--white, #FFF);
  text-align: center;
  font-family: "TOMOBossaBlack";
  font-size: 21.583px;
  font-weight: 900;
  border: none;

}

  
@media only screen and (min-width: 851px) and (max-width: 1450px) {
  .cookie-popup {
      width: 80%;

  }
}
  @media only screen and (max-width: 850px)  {
    .cookie-popup {
        width: 100%;
        flex-direction: column;
    }
  }
