@font-face {
  font-family: 'TOMOBossaBlack';
  src: url('../fonts/TOMOBossaBlack.ttf') format('truetype');
}

.navbar {
  padding: 15px 8%;
  z-index: 1001;
  position: sticky;
  top: 0;
}
.fixed {
  position: fixed;
  top: 0;
  width: 100%; /* Assurez-vous que la navbar s'étend sur toute la largeur */
}


  .navbar-content {
    display: flex;
    gap: 50px;
  }
  
  .nav-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-link {
    color: var(--white);
    text-align: center;
    font-family: "TOMOBossaBlack";
    font-size: 18px;
    font-weight: 900;
  }

  .nav-link:hover {
    text-shadow: 2px 2px 0px var(--orange);
  }
  
  .nav-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--white);
  }
  .navbar.nav-bg-dark-blue, .nav-items.open {
    background-color: var(--dark-blue);
  }

  

  
  @media (max-width: 768px) {
    .nav-items {
      position: fixed;
      right: 0;
      top: 55px;
      background-color: var(--dark-blue);
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      display: none;
      z-index: 1000;
    }
    .navbar-content {
      flex-direction: column;
    }
    .nav-items.open {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 50px;
      padding-top: 100px;
      height: 100vh;
    }
    .nav-toggle {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
  