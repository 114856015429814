.footer-section {
    padding: 15px 8%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
    background-color: var(--white);
}
ul {
    display: flex;
    align-items: center;
    gap: 35px;
    
}
li a {
    color: #1F233C;
    text-align: center;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 15px */
}
li a:hover {
    text-decoration: underline;
}
.footer-icons {
    display: flex;
    align-items: center;
    gap: 25px;
}
.footer-icons i::before {
    font-size: 25px;
    color: var(--dark-blue);
}

@media only screen and (max-width: 750px) {
    .footer-section {
        flex-direction: column;
        gap: 20px;
    }
}