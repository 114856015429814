@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@font-face {
  font-family: 'TOMOBossaBlack';
  src: url('../fonts/TOMOBossaBlack.ttf') format('truetype');
}

:root {

    /*========== Color ==========*/
    --white: #fff;
    --dark-blue: #1F233C;
    --med-blue: #377DDD;
    --light-blue: #23CFDD;
    --yellow: #FDCF07;
    --orange: #FC7200;


    /*========== Font family ==========*/
    --outfit: 'Outfit', sans-serif;
}

/*==================== BASE ====================*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    
}

html {
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden ;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
input:focus{
    outline: none;
}

img {
  max-width: 100%;
  height: auto;
}


body {
  background-color: var(--body-purple);
  overflow-x: hidden;
}

.no-scroll {
  overflow-y: hidden;
}
.cta-btn {
    display: flex;
    padding: 0px 20px 4px 20px;
    justify-content: center;
    align-items: center;
    gap: 7.027px;
    border-radius: 0.8px;
    background: #FDCF07;
    box-shadow: 3.2px 3.2px 0px 0px #FC7200;
    color: var(--white, #FFF);
    text-align: center;
    font-family: "TOMOBossaBlack";
    font-size: 25px;
    font-weight: 900;
    border: none;
    z-index: 100;
}
.cta-btn:hover {
  cursor: pointer;
  scale: 1.05;
  transition: ease-in-out;
}
.section {
  padding: 80px 12%;
}
.disabled {
  display: flex;
  padding: 0px 20px 4px 20px;
  justify-content: center;
  align-items: center;
  gap: 7.027px;
  border-radius: 0.8px;
  background: #a1a1a1;
  box-shadow: 3.2px 3.2px 0px 0px #505050;
  color: var(--white, #FFF);
  text-align: center;
  font-family: "TOMOBossaBlack";
  font-size: 21.583px;
  font-weight: 900;
  border: none;
  cursor: not-allowed !important; 

}
.cta-btn.disabled:hover, .cta-btn:disabled:hover {
  cursor: default;
  scale: 1; /* Keep scale at default /
  transition: none; / No transition */
}
.parallax-coin {
  transition: transform 0.2s ease-out;
}
.yellow-text {
  color: var(--yellow);
}




@media only screen and (max-width: 850px) {
  .section {
    padding: 30px 5%;
  }
  .parallax-coin {
    scale: 0.5;
  }
}

.email-preview{
  display: flex;
  justify-content: center;
  background-color: grey;
}


.privacy-bg {
  background-color: var(--dark-blue);
}
.privacy-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px 10%;
}
.privacy-content ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.privacy-content ul li {
  list-style: inside;
}
